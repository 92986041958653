@import "./../Variables.scss";

// Note: the Timeline component sits inside a Block component
#timeline {
  background-color: $background-color-primary; //$color-black;
  padding-top: 20px;

    div.header-adventurer {
      display:flex;
      flex-direction: row;
      // background-color: $color-white;
      color:$color-green;
      box-sizing: border-box;
      padding:0 0 0 0;
      justify-content: space-between;
      margin-bottom: 4px;
      padding: 0 0 10px 0;


      div.header-adventurer-first,
      .header-adventurer-second {
        width: 100%;
        // margin-right:4px;
        

        span {
          display: inline-block;
          text-align: center;
          width: 100%;
          color:$color-green;
          padding-top:5px;
        }

        textarea {
          padding: 15px;
          border:1px solid $color-green;
          border-radius: 4px;
          outline:none;
          width:100%;
          resize:vertical;
          font-size:16px;
          font-weight:100;
          color:$color-black;
        }
      }

      div.header-adventurer-first{
        display: flex;
        gap: 10px;
      }

      div.header-adventurer-second{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .commentButton{
          display: flex;
          justify-content: flex-end;
        }
        .disabled button{
          background-color: #aadbb1;
        }
      }

      div.button-share {
        border:1px solid $color-green;
        background-color: white;
        width:32px;
        height:32px;
        min-width: 32px;
        box-sizing: border-box;
        padding-top: 3px;
        user-select: none;
        border-radius: 4px;
        img {
          width:24px;
          height:24px;
        }
      }
      .share{
        padding: 5px 10px !important;
      }

      div.button-message {
        width:32px;
        height:32px;
        min-width:32px;
        border:1px solid $color-green;
        border-radius: 4px;
        box-sizing: border-box;
        padding:4px;
        img {
          width:100%;
          height: auto;
        }
      }

      div.send-message.disabled {
        border-color:#666666;
      }

      div.write-message {
        width: 100%;
        padding: 0 4px 0 4px;

      }

    }

    div.header-watcher {
      color:black;
      padding:16px 0 40px 0;
      margin-bottom: 8px;
      margin-top:0;
      position:relative;
      font-size:14px;
      text-align: center;

      div.header-watcher-title {
        font-size: 1.5em;
        line-height: 1.1em;
        padding: 0 4px 12px 4px;
      }
      div.header-watcher-explanation {
        font-size: 1.0em;
        line-height: 1.1em;
        padding: 0 4px 12px 4px;
      }


      .header-watcher-pulse {
        width: 10px;
        height: 10px;
        border-radius: 100% 100%;
        position: absolute;
        bottom:16px;
        left: 50%;
        transform: translate(-50%,-50%);
        background: rgba(127, 185, 0, 1);
      }

      .header-watcher-pulse-inner {
        border-radius: 20px;
        line-height: 20px;
        text-align: center;
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        text-transform: uppercase;
        letter-spacing: 0.05em;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(127,185,0, 0.2);
          border-radius: 50px;
          opacity: 0;
        }

        &:before {
          transform: scale(1);
          animation: header-watcher-pulse 3s infinite linear;

        }

        &:after {
          animation: header-watcher-pulse 3s 2s infinite linear;
        }
      }

      @keyframes header-watcher-pulse {
        0% {
          transform: scale(0.6);
          opacity: 0;
        }
        33% {
          transform: scale(1);
          opacity: 1;
        }
        100% {
          transform: scale(1.4);
          opacity: 0;
        }
      }


    }

    div.is-posting {
      padding:8px 0 16px 0;
      color:$color-green;
      text-align: center;
    }

    div.timeline-events {
      padding:0;
    }

    div.timeline-countdown {
      padding:16px 0 32px 0;
      color:black;
      div.explanation {
        font-size:18px;
        padding-bottom:32px;
      }
    }
  //}




}
