@import "./../Variables.scss";

div.c-loading {
  margin:4px 0px 4px 0px;
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;

}

div.c-loading:before{
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  background-color: $color-green;
  animation: c_loading_line_keyframes 2s linear infinite;
}

@keyframes c_loading_line_keyframes {
  from {left: -200px; width: 30%;}
  50% {width: 30%;}
  70% {width: 70%;}
  80% { left: 50%;}
  95% {left: 120%;}
  to {left: 100%;}
}