@import "./../Variables.scss";

// Note: the Error component sits inside a Block component

div.error {
  background-color: white;

  margin:8px 0 8px 0;

  padding-top: 16px;
  padding-bottom: 16px;
  div.block-inner {

    color:$color-error;

    div.title {
      padding:0 0 16px 0;
      font-size: 24px;
      font-weight: 200;
    }

    div.message {
      font-size: 18px;
      line-height: 22px;
    }
  }
}