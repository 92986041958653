@import "./../Variables.scss";

div#memory-lane {
  background-color: $color-green;
  padding:16px 0 32px 0;
  p {
    padding-bottom: 8px;
    color: white;
    padding: 10px 20px;
  }
  a {
    font-weight: 100;
    color:$color-green;
    background-color: white;
    text-decoration: none;
    padding:8px 16px 8px 16px;
    border-radius: 5px;
  }
}