#modal-share {
  div.explanation {
    text-align: center;
    padding:16px 0 16px 0;
    font-size:1em;
    line-height: 22px;
    color: white;
  }
  div.items {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    div.item {
      width:48px;
      cursor:pointer;
      img {
        width:100%;
        height:auto;
      }
    }
  }
  div.console {
    padding:32px 0 0 0;
    text-align: center;
  }
}