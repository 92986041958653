@import "./../Variables.scss";

div.appentura-progress {
  position: relative;
  span.part {
    box-sizing: border-box;
    display:inline-block;
    height:2px;
    position: relative;

    span.divider {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      position: absolute;
      top:-3px;
    }
    span.divider.active {
      background-color: $color_green;
    }
    span.divider.inactive {
      background-color: #444444;
    }
    span.divider.common {
      left:-2px;
    }
    span.divider.last {
      right:-4px;
    }
  }

  span.part.active {
    background-color: $color_green;
  }
  span.part.inactive {
    background-color: #444444;
  }
}