@import "../../Variables.scss";

div.google-review-text,
div.google-review-button {
  text-align: center;
}

div.google-review-text {
  padding-bottom: 16px;
}

div.google-review-button {
  a {
    div.google-review-image {
      width: 100px;
      display: inline-block;
      padding: 8px;
      background-color: white;
      border: 1px solid $color-green;
      border-radius: 4px;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.button {
  font-weight: 100;
  color: white;
  background-color: $color-green;
  text-decoration: none;
  padding: 8px 16px 8px 16px;
  border-radius: 5px;
  width: fit-content;
  margin: 20px auto;

}

.pato-duck-content {
  text-align: center;

  .duck {
    padding: 10px 0;
    width: 50%;
  }

  .code {
    font-weight: 900;
    color: $color-green;
  }

  .button {
    font-weight: 100;
    color: white;
    background-color: $color-green;
    text-decoration: none;
    padding: 8px 16px 8px 16px;
    border-radius: 5px;
    width: fit-content;
    margin: 20px auto;
  }
}


/*
div.google-review {
  width: 200px;
  a.google-review-link {
    font-weight: 100;
    background-color:$color-green;
    color: white;
    text-decoration: none;
    padding:8px 16px 8px 16px;
    border-radius: 2px;
    text-transform: uppercase;
    img {
      width: 100%;
      height: auto;
    }
  }
}
*/