div.appentura-loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  position: relative; }
div.appentura-loader div.bounce1, div.appentura-loader div.bounce2 {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  animation: loader-bounce 2.0s infinite ease-in-out; }
div.appentura-loader div.bounce1 {
  background-color: #6AA84F; }
div.appentura-loader div.bounce2 {
  background-color: #6AA84F;
  animation-delay: -1.0s; }

@keyframes loader-bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }
