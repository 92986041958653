@import "./../Variables.scss";

div.c-modal {
  // position
  position:fixed;
  left:0; top:0;

  // sizing, padding, border
  box-sizing: border-box;
  width:100%; height:100%;
  padding:48px 16px 96px 16px;
  z-index:$z-index-modal;

  // color
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
  color:white;

  div.c-modal-close {
    cursor:pointer;
    user-select: none;
    color:black;
    background-color: white;
    display:inline-block;
    font-size:20px;
    font-weight: bolder;
    position:absolute;
    top: 8px;
    right:8px;
    border-radius:50%;
    text-align: center;
    width:32px;
    height:32px;
  }
  div.c-modal-title {
    text-align: center;
    font-size:28px;
    padding:8px;
  }
  div.c-modal-content {
    position:relative;
    height:100%;
    width:100%;
    overflow-y: hidden; //scroll;
  }

}