@import "./../Variables.scss";

// Note: #header is an instance of the Block component and as such inherits the styling done there and in Block.scss

#footer-explanation {
  box-sizing: border-box;
  padding: 30px 20px;
  background-color: $background-color-secondary;
  div.title {
    font-size: 1.2em;
    font-family: $fontfamily_bold;
    color: $color-purple;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
  }
  div.text {
    font-size: 1em;
    font-weight: 100;
    padding: 4px 0 16px 0;
  }
  div.icon {
    font-size: 35px;
    color: $color-purple;
  }
}

#footer-buy-cta {
  padding: 30px;
  a {
    padding:8px;
    color:white;
    border-radius: 2px;
    text-decoration: none;
    background-color: $color-green;
  }

}


#footer-contact {
  box-sizing: border-box;
  padding: 25px 0;
  background-color: $color-green;
  color:white;

  a{
    color: white;
    padding: 5px 0;
  }

  div.social-icons {
    box-sizing: border-box;
    display:flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    .icon{
      background-color: white;
      color: #54ac60;
      border-radius: 50%;
      width: 32px;
      min-width: 32px;
      max-width: 32px;
      height: 32px;
      font-size: 22px;
      a{
        color: $color-green;
      }
    }
    div.social-icon {
      box-sizing: border-box;
      display: inline-block;
      width:40px;
      padding:4px;

      img {
        width: 100%;
        height: auto;

      }
    }
  }

}

