@import "./../Variables.scss";

#modal-selfie-done {
  position: relative;
  color: white;

  #modal-selfie-done-info {
    padding:16px 0 32px 0;

  }

  #modal-selfie-done-console {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap:5px;
    button{
      padding: 8px !important;
    }
  }
}