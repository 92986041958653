@import "./../../Variables.scss";

// something
div.event-selfie {
  div.image {
    width:100%;
    img {
      width:100%;
      height:auto;
    }
  }
}