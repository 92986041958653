@import "./../Variables.scss";

div.c-map {
  padding-top:0;
  div.map {
    width: 100%;
    height: 300px;
    margin-bottom:16px;
  }
  a.link {
    color:$color-green;
    div {
      background-color: white;
      border-radius: 2px;
      padding: 8px 16px 8px 16px;
      display: inline-block;
      margin-bottom: 16px;
      font-weight: 200;
    }
  }
  a.link:hover {
    text-decoration: none;
    color:$color-green;
  }
}