@import './Variables.scss';

* {
  box-sizing: border-box;
}

body {
  // sizing, padding, and margins
  height: initial;
  margin: 0;
  padding: 0;

  // colors
  background-color: $background-color-primary;
  color: $font-color;
}

// font
body, input, textarea, h1, h2, h3, h4, div, p, button, select {
  font-family: $fontfamily_light;
  font-size: 16px;
  line-height: 1.4em;
}


h1, h2, h3, h4 {
  font-family: $fontfamily_bold;
  padding:0;
  margin:0;
}

h1 {
  font-size:24px;
  line-height: 1.2em;
  padding-bottom: 12px !important;
}

h2 {
  font-size:20px;
  line-height: 1.2em;
}

label, .ui.accordion .title {
  color: $font-color !important;
}

p, ul {
  font-size: 1em;
}

p {
  line-height: 20px;
  padding: 10px 0;
  margin: 0;
}

div {
  box-sizing: border-box;
}

//
div.block {
  width:100%;
  color:$font-color;
  text-align: center;
  position:relative;

  div.block-inner {
    position: relative;
    display: inline-block;
    width:100%;
    max-width: $inner-max-width;
    padding:0 4px 0 4px;
  }

  div.block-inner.main {
    text-align: left;
    padding:32px 0 32px 0;
  }
}

div.block > div {
  width:100%;
  max-width: $inner-max-width;
  margin-left:auto;
  margin-right:auto;
}


a {
  color: $color-green;
  text-decoration: none;
}

a:hover, a:active {
  color: $color-green-light;
  text-decoration: underline;
}


button {
  outline:none;
  cursor:pointer;
  box-sizing: border-box;
  margin:0;
  padding:10px 16px 10px 16px;
  background-color: $color-green;
  border:none;
  border-radius: 2px;
  color:white;
  font-size: 16px;
  font-weight: 200 !important;
}

button.red {
  background-color: red;
  color:white;
}


.ui.button, .ui.button:active, .ui.button:focus  {
  background-color: $color-green;
  border-color: $color-green-dark;
  color:#FFF;
}


.ui.button:hover {
  background-color: $color-green-light;
  border-color: $color-green-light;;
  color:#FFF;
}

.ui.star.rating .icon {
  outline: none;
}

p.error {
  color: $color-error;
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: $color-green;
  border-color: $color-green;
}

.btn-file {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

.btn-file.link {
  color: $color-green;
  cursor: pointer;
  display: block;
}

.btn-file.link:hover {
  text-decoration: underline;
  outline: 0;
}

select {
  border:0;
  padding:4px;
  outline: none;
}

/** fonts **/
@font-face {
  font-family: "FuturaBT-Bold";
  src: url("/fonts/FuturaBT/FuturaBT-Bold.woff2") format("woff2"), url("/fonts/FuturaBT/FuturaBT-Bold.woff") format("woff");
 }
 
 @font-face {
  font-family: "FuturaBT-Medium";
  src: url("/fonts/FuturaBT/FuturaBT-Medium.woff2") format("woff2"), url("/fonts/FuturaBT/FuturaBT-Medium.woff") format("woff");
 }
 
 @font-face {
  font-family: "FuturaBT-Book";
  src: url("/fonts/FuturaBT/FuturaBT-Book.woff2") format("woff2"), url("/fonts/FuturaBT/FuturaBT-Book.woff") format("woff");
 }