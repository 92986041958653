@import '../../Variables.scss';

#surprise-ordered {
  #surprise-ordered-heavyworkload {
    background-color: $color-green;
    color:white;
    margin:0 0 16px 0;
    padding:16px;
    font-size: 16px;
  }
  // TODO no longer used?
  /*
  #surprise-ordered-info {
    div.title {
      font-size:24px;
    }
    background-color: $color-green;
    color:white;
    margin:0 0 16px 0;
    padding:16px;
    font-size: 16px;
  }
  */
  #surprise-ordered-intro {
    .reserver-name{
      color: $color-green;
      font-family: $fontfamily_bold;
    }
    .personal-comment {
      padding:16px;
      background-color: #ffffffcf;
      color:$color-black-off;
      text-align: center;
      position: relative;
      border-radius: 8px;
      margin: 15px 0;
    }
    .personal-comment:before {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 0;
      border: 17px solid transparent;
      border-bottom-color: #ffffffcf;
      /* border-bottom: 0; */
      border-top: 0;
      border-right: 0px;
      margin-left: -95px;
      margin-top: -17px;
    }
    /*
    .personal-comment:after {
      content: '';
      position: absolute;
      top: 0;
      left: 40px;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      border-bottom-color: $color-green-lightest;;
      border-top: 0;
      border-left: 0;
      margin-left: -5px;
      margin-top: -10px;
    }
    */
  }
  h1{
    color: #54ac60;
  }
}

.title{
  margin: 30px 0px 0px 0px;
}

.newsletter-checkbox{
  margin: 15px 0 0 0;
}

.button{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.ui.fluid.input{
  margin: 5px 0;
}

.placeinput{
  input{
    border: none;
    border-radius: 3px;
    padding: 15px !important;
  }
}

input, textarea, .dropdown{
  border: none !important;
}

.transportSelection{
  padding: 5px 25px 5px 10px !important;
  .icon{
    padding: 9px 3px !important;
  }
}