@import "./../../Variables.scss";

div.event-activity {
  div.activity-image-and-description {
    display: flex;
    flex-direction: row;
    padding:6px 0 8px 0;

    div.activity-image {
      box-sizing: border-box;
      padding:2px 0 0 0;
      width:50%;
      img {
        width:100%;
        height:auto;
      }
    }
    div.activity-description {
      padding:0 0 0 4px;
      text-align: left;
      color:$color-black-off;
      box-sizing: border-box;
      width:50%;
      p {
        font-size:1.1em;
        line-height: 1.2em;
        padding:0;
        margin:0;
      }
    }
  }

  div.activity-more {
    padding: 0 0 8px 0;
    a {
      color:$color-green;
      text-decoration: underline;
    }
  }
}