@import '../../Variables.scss';

#surprise-preparing-message {
  div.title {
    font-size:24px;
  }
  background-color: $color-green;
  color:white;
  margin:0 0 16px 0;
  padding:16px;
  font-size: 16px;
}