@import './../Variables.scss';

// Note: #header is an instance of the Block component and as such inherits the styling done there and in Block.scss
#header {
  position:relative;
  top:0;left:0;
  padding: 8px;
  // height:$header-height;
  color: #54ac60;
  text-align: center;
  background: $background-color-gradient;
  z-index: $z-index-top;

  div { // this is the block component's inner div
    display: flex;
    justify-content: space-between;
    align-items: center;
    div.logo {
      box-sizing: border-box;
      height: 46px;
      padding:3px 0 3px 0;
      img {
        height:100%;
        width:auto;
      }
    }

    div.button {
      position: absolute;

      // width: $header-button-width;
      // height: $header-button-width;
      font-size: 18px;
      cursor: pointer;
      border: 1px solid white;
      color: white; 
      border-radius: 50%;
      padding: 7px;
      margin: 0;

      .phone, .chatt {
        color: white;
        font-size: 25px;

      }
      a{
        line-height: 1em;
        color: white;
      }
      // border-radius: 50%;

      img {
        height:100%;
        width:auto;
      }
    }

    div.help {
      left:4px;
    }

    div.phone {
      right:($header-height - $header-button-width) + $header-button-width;
    }

    div.chat {
      right:($header-height - $header-button-width) / 2;
    }
  }
}

#header.fixed {
  position: fixed;
}

.transportSelection{
  margin: -2px -5px 0px 0px !important;
  min-height: unset !important;
}