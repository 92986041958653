#countdown {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 24px;
}

#countdown table {
  width: 100%;
  text-align: center;
}

#countdown td {
  width: 25%;
}

#countdown tr:last-child {
  font-size: 14px;
}
