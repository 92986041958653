@import "./../../Variables.scss";

// something
div.event-travel {
  div.travel-text {
    padding: 8px 0 8px 0;
    color:$color-black-off;
    font-size: 1.3em;
    line-height: 1.3em;
  }
}