@import '../../Variables.scss';

#root, #app {
  min-height: 100%; // TODO fud ... necessary, does belong here?
}

div {
  box-sizing: border-box; // TODO somewhere centralized please
}

#instructions-title,
#instructions-minimized {
  display: flex;
  align-items: center;
  justify-content: center;
  padding:4px 4px 4px 4px;
  width:100%;
  text-align: center;
  div.title {
    display: inline-block;
    font-size:24px;
    font-weight: 200;
    // line-height: 28px;   
    user-select: none;
    margin: 0px;
  }
}

#instructions {
  padding-top:$header-height;
  padding-bottom:$header-height;
  z-index: $z-index-bottom;

  #instructions-title {
    // styled above together with instructions-minimized
    
    // exception for padding styled individual
    padding: 0 20px;
  }
  #instructions-text,
  #instructions-attachments {
    padding:0 20px;
    user-select: none;
    p, li {
      font-size:16px;
      line-height: 18px;
      padding-bottom: 4px;
    }
    div.block-inner {
      text-align: left !important;
    }
  }
  #instructions-image {
    padding: 20px;
    display: flex;
    justify-content: center;
  
    .image-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
    
      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }
    
      .image-overlay {
        position: absolute;
        bottom: 15px;
        color: white;
        text-shadow: 1px 1px 5px black;
      
        div {
          font-family: 'Dancing Script', cursive;
        }
      }
    }
  }
  #instructions-progress {
    padding: 10px 0;
    
  }
  #instructions-navigation {
    /*

    */
    div.block-inner {
      div.buttons {
        //padding:4px;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 16px;
      }

    }
  }
}

#instructions-minimized {
  position: fixed;
  height:$header-height;
  z-index: $z-index-middle;
  background-color: $color-green;
  color:white;


  overflow:hidden;

  transition: top 0.4s;

  div.title {
    //overflow:hidden;
    white-space: nowrap;
  }
}

#instructions-minimized.hidden {
  top:0;
}

#instructions-minimized.visible {
  top:$header-height;
}



#secondary {
  position:fixed;
  left:0;
  height: $header-height;
  width:100%;
  z-index: $z-index-top;

  #secondary-chevron {
    text-align: center;
    width:100%;
    position:absolute;
    top:-12px;
    z-index:$z-index-top + 1;
    #secondary-chevron-inner {
      display:inline-block;
      width:24px;
      height:24px;
      img {
        transition: transform 0.7s;
      }
      img.minimized {
        transform:rotate(180deg);
      }
      img.maximized {
        transform:rotate(0);
      }
    }

  }

  #secondary-header {
    padding: 8px 4px 8px 4px;
    height: $header-height;
    background-color: white;

    display: flex;
    // align-items: center;

    div.block-inner {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      div.heading {
        padding-top:3px;
        text-align: center;
        color:$color-green;
        font-size: 20px;
        span {
          position: relative;
          span.notification-badge {
            width: 16px;
            height:16px;
            background-color: red;
            border-radius: 50%;
            display: inline-block;
            position: absolute;
            top:-3px;
            right:-8px;
            box-shadow: 1px 1px 4px #656565;
            img {
              width:12px;
              height:auto;
              position:absolute;
              left:2px;
              top:2px;
              animation: ring-my-bell 0.8s;
              animation-iteration-count: 2;
            }
          }
        }
        @keyframes ring-my-bell {
          10%, 90% {
            transform: translate3d(-1px, 0, 0);
          }

          20%, 80% {
            transform: translate3d(1px, 0, 0);
          }

          30%, 50%, 70% {
            transform: translate3d(-2px, 0, 0);
          }

          40%, 60% {
            transform: translate3d(2px, 0, 0);
          }
        }
      }

      div.button {
        width:32px;
        min-width: 32px;
        height:32px;
        box-sizing: border-box;
        padding-top: 3px;
        border:1px solid $color-green;
        user-select: none;
        border-radius: 4px;

        img {
          width:24px;
          height:24px;
        }

        .inputfile {
          width: 0.1px;
          height: 0.1px;
          opacity: 0;
          overflow: hidden;
          position: absolute;
          z-index: -1;
        }
        .inputfile + label {
          cursor:pointer;
        }

      }
      div.button.camera {
        background-color: $color-green;
        margin: 0px;
      }
      div.button.empty {
        border:none;
      }
    }


  }



  transition: top 0.4s;
}

#secondary-explain {
  padding:4px 0 4px 0;
  text-align: center;
  color:$color-black-off-off;
}

#secondary.minimized {
  top:calc(100% - 60px);
  // TODO  not sure how well this is supported ... but I really cannot see how else to animated the transition (cannot transition from bottom to top property)
  // TODO 48px hardcoded ... wanted to use $header-height ... don't know how ...

  // DO NOT DELETE THIS: if the above does not work: bottom:0;

  #secondary-header {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    //border-top: 1px solid $color-green;
  }

}
#secondary.maximized {
  top:$header-height + $header-height;
  #secondary-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }
}

#secondary {
  height:100%;
  background-color: black;
  #secondary-content {
    max-height: 100%;
    overflow-y: scroll;
  }
  #secondary-content::-webkit-scrollbar {
    display: none;
  }
}

#secondary {
  #footer-contact {
    // because the secondary view messes things up vertically we add some padding to the bottom of the footer
    padding-bottom: (4 * $header-height) !important;
  }
}


#timeline {

  div.timeline-event {
    color:black;
    background-color: black;
    padding:4px;
    div.timeline-event-inner {
      background-color: white;
      padding:4px;
      div.timeline-event-title {
        font-size:24px;
        color:darkgreen;
        padding:8px 0 8px 0;
      }
      div.timeline-event-text {
        padding:8px 0 16px 0;
      }
    }
  }
}