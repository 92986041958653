@import "./../../Variables.scss";

div.event-review {
  div.review-text {
    padding:8px 0 8px 0;
    text-align: center;
    font-size: 1.3em;
    color:$color-black-off;
  }

  div.review-stars {
    font-size: 26px;
    line-height: 26px;
    padding:8px 0 8px 0;
    .star.full {
      color: gold;
    }
    .star.empty {
      color:silver;
      //color: #292929;
    }
  }
}