$header-height: 60px;       // Make sure to change in Ready.js when changing here
$header-button-width:32px;

$inner-max-width:400px;

$color-green: #54ac60;
$color-green-dark: #3d612e;
$color-green-light: #83cf62;
$color-green-lightest:rgb(243, 253, 239); 
$color-purple: #7b4997;

$color-black: #000000;
$color-black-off: #272727;
$color-black-off-off: #3a3a3a;

$color-white: #FFFFFF;
$color-white-off: #f2f2f2;

$color-error: #ca1126;

$z-index-modal:2000;
$z-index-top:1000;
$z-index-middle:500;
$z-index-bottom:200;

// TODO do not use the values below anywhere
$z-index-base:0;
$z-index-header:400;
$z-index-instructions:100;
$z-index-instructions-minimized:300;
$z-index-bottom:200;

/** font families
  note: the terrible font selected does not support font-weight, so to achieve thin, regular, bold we have to use font-families ...
**/
$fontfamily_light: "FuturaBT-Book", sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$fontfamily_normal: "FuturaBT-Medium", sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
$fontfamily_bold: "FuturaBT-Bold", sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

// background-colors
$background-color-primary: #ebf3e9;
$background-color-secondary: #E9E3F2;
$background-color-tertiary: #7b4997;
// background with gradient
$background-color-gradient: linear-gradient(90deg,#7b4997,#7b4997 25%,#54ac60 66%,#54ac60);
// font-color
$font-color: #444;