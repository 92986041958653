@import "./../../Variables.scss";

div.event {
  box-sizing: border-box;
  border-radius: 0;
  padding:20px 0 !important;
  margin-bottom: 12px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 2px 1px;


  // &:first-child{
  //   border: 0px;
  // }

  /*

  @keyframes event_appear {
    0% {
      transform: scale(0)
    }
    100% {
      transform: scale(1)
    }
  }

  animation: 1s event_appear;
  */


  div.heading {
    display:flex;
    flex-direction: row;
    padding:4px 4px 0 4px;
    width: 85%;
    div.icon {
      display:inline-block;
      box-sizing: border-box;
      width:40px;
      height:40px;
      min-width: 40px;
      min-height: 40px;
      max-width:40px;
      max-height: 40px;
      border-radius: 50%;
      border:1px solid white;
      padding:4px;
      background-color: $color-green;
      img {
        width:100%;
        height:auto;
      }
    }

    div.title-and-timestamp {
      padding:2px 0 4px 4px;
      div.title {
        padding:0;
        color:$color-black-off-off;
        text-align: left;
        font-size: 1.3em;
        font-weight: 200;
      }
      div.timestamp {
        padding:0;
        text-align: left;
        color:$color-black-off;
        font-size: 0.8em;
      }
    }
  }

  div.content {
    padding:10px 4px;

    img{
      border-radius: 5px;
    }
  }

  div.reactions-and-actions {
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 7px;

    div.actions{
      position: absolute;
      top: 5px;
      right: 5px;
    }

    div.actions, div.reactions {
      display:flex;
      flex-direction: row-reverse;
      padding:4px 2px 4px 2px;

      div.reaction{
        box-sizing: border-box;
        padding:2px;
        display:inline-block;
        position: relative;
        width:30px;
        height:30px;
        img {
          width:100%;
          height:auto;
        }
      }
      div.action {
        box-sizing: border-box;
        padding:3px;
        display:inline-block;
        position: relative;
        width:30px;
        height:30px;
        background-color: $color-green;
        border-radius: 5px;
        color: white;
        img {
          width:100%;
          height:auto;
        }
      }

      div.reaction.watcher.enabled {
        img {
          //filter:grayscale(100%);
          opacity: 0.5;
        }
      }
    }


    div.reactions {
      div.reaction {
        div.count {
          padding: 1px 4px 1px 4px;
          border-radius: 4px;
          position: absolute;
          background-color: #343434;
          color:white;
          font-size: 8px;
          line-height: 9px;
          bottom:1px;
          right:1px;
          height:8px;
        }
      }

    }

    div.actions {
      div.action-confirm {
        box-sizing: border-box;
        //transform: rotateX(80deg);
        display:flex;
        flex-direction: row;
        div.question,
        div.answer {
          box-sizing: border-box;
          // padding:4px 4px 4px 4px;
          font-size:18px;
        }
        div.question {
          color:black;
          padding-right: 10px;
        }
        div.answer {
          cursor:pointer;
          text-align: center;
          width:50px;
        }
        div.answer.yes {
          background-color: $color-green;
          color: white;
          margin-right: 4px;
          border-radius: 5px;
        }
        div.answer.no {
          background-color: $color-error;
          color: white;
          border-radius: 5px;
        }
      }
    }
  }


}
