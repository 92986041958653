#modal-help {
  div.explanation {
    text-align: center;
    padding:16px 0 32px 0;
    font-size:20px;
    line-height: 22px;
  }
  div.console {
    padding:32px 0 0 0;
    text-align: center;
  }
}