@import "../../Variables.scss";

#surprise-prepaid {
  height:100%;
  padding-bottom:16px;
  background-color: #fefefe;
  color:$color_black_off;
  border-top:4px solid black;

  div.prepaid-info {
    padding-bottom:32px;
  }

  div.prepaid-form {
    label {
      display:block;
      padding:2px 0 4px 0;
      color:$color_black_off !important;
    }
    input[type=text],
    input[type=email],
    input[type=phone],
    select,
    textarea {
      border:0;
      border-bottom:1px solid $color_green;
      width: 100%;
      padding:8px 4px 8px 4px;
      outline:none;
      line-height: 1em;
      background-color: $color-green-lightest;
    }

    select,
    input[type=text]:focus,
    input[type=email]:focus,
    input[type=phone]:focus,
    textarea:focus {
      border-bottom:2px solid $color-green;
    }

    select,
    input[type=text]:disabled,
    input[type=email]:disabled,
    input[type=phone]:disabled,
    textarea:disabled {
      border-bottom:2px solid silver;
    }

    p.invalid {
      position: relative;
      select,
      input[type=text],
      input[type=email],
      input[type=phone] {
        position: relative;
        border-color: red;
        background-color: #ffe9e9;
      }
      label::before {
        content:"!";
        background-color: white;
        color:red;
        position: absolute;
        top: 0;
        left: -10px;
        padding:2px;
      }
    }
    /*
    p.invalid::after {
      content: "!";
      background-color: white;
      color:red;
      position: absolute;
      top:2px;
      right:2px;
    }
    */

    h2 {
      margin-bottom:8px;
    }
    div.prepaid-form-submit {
      padding:16px 0 16px 0;
    }

    div.prepaid-form-error {
      padding:16px;
      border:1px solid red;
      color:red;
      background-color: white;
      /*
      background-color:red;
      color: white;
      */
    }

    button:disabled {
      background-color: silver;
    }
  }
}