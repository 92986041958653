@import "./../Variables.scss";

#modal-selfie {
  position:relative;
  #modal-selfie-image {
    width:100%;
    height:auto;
    text-align: center;
    img {
      width:100%;
      height:auto;
    }
  }
  #modal-selfie-console {
    background-color: rgba(24, 24, 24, 0.5);
    padding:8px 8px 8px 8px;
    position:absolute;
    left:0;
    top:0;
    width:100%;

    #modal-selfie-buttons {
      display:flex;
      flex-direction: row;
      justify-content: space-between;
      button {
        min-width:120px; // same width as the checkbox control
      }
    }

    #modal-selfie-marketing {
      padding:0 0 16px 0;

      //justify-content: space-between;

      div.text {
        display:block !important;
        text-align: center;
        user-select: none;
        color:white;
        font-size:15px;
        padding-left: 8px;
      }

      div.checkbox {
        label {
          display:inline-block;
          min-width:24px;
          max-width: 24px;
          height:24px;
          border:1px solid white;
          border-radius:2px;
        }

        input[type=checkbox] {
          display:none
        }

        input[type=checkbox] + label {
          background:none;
          border-color: #656565;
        }

        input[type=checkbox]:checked + label {
          background-color:$color-green;
        }

        input[type=checkbox]:checked + label:after {
          margin-top:1px;
          margin-left:7px;
          content:'';
          display:block;
          width:8px;
          height:16px;
          border: solid white;
          border-width: 0 4px 4px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}